import { IConfig, useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { Config } from './env';
import { FC, useEffect } from 'react';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { useAuth } from 'oidc-react';

export interface FeatureFlags {
  flagsReady: boolean;
  guideCms: boolean;
  dataSources: boolean;
  committeeMeetings: boolean;
}

export const getUnleashConfig = (config: Config): IConfig => ({
  url: `${config.unleashUrl}/api/frontend`,
  clientKey: config.unleashAccessToken,
  appName: 'longhouse-web',
  disableRefresh: true,
});

export const useFeatureFlags = (): FeatureFlags => {
  const status = useFlagsStatus();

  return {
    guideCms: useFlag('longhouse-guide-cms'),
    dataSources: useFlag('longhouse-datasources'),
    committeeMeetings: useFlag('longhouse-committee-meetings'),
    flagsReady: status.flagsReady,
  };
};

export const UnleashContextUpdater: FC = () => {
  const auth = useAuth();
  const updateContext = useUnleashContext();
  useEffect(() => {
    if (auth?.userData?.profile?.email) {
      updateContext({ userId: auth.userData.profile.email.toLowerCase() });
    }
  }, [auth?.userData?.profile]);

  return null;
};
