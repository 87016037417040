import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton, ListLabel, StyledAnchor, StyledLink } from '../Components';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE, GET_STANDARD, UPDATE_EXTERNAL_ID, UPDATE_TITLE } from './standards.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { useParams } from 'react-router-dom';
import { StyledPaper } from '../theme';
import { GetStandardQuery, GuideStatus } from '../__generated__/graphql';
import { ColWidths, TableCellHead, TableCellIcon, TableCellEllipsis } from '../components/TableComponents';
import { formatDateOnly } from '../Formatters';
import { GuideStatusIcon } from '../guides/GuideStatusIcon';
import { EditableTextField } from '../components/EditableTextField';
import { Edit } from '@mui/icons-material';
import { Modal, useModal } from '../dialogs/useModal';

export function StandardDetails() {
  const { id } = useParams();

  const { data, loading } = useQuery(GET_STANDARD, { variables: { id: id } });
  if (loading) return <LoadingSpinner />;
  if (!data?.standard) return <Typography>Fant ikke standarden</Typography>;

  const standard = data.standard;
  const guides = standard?.guides != null ? standard.guides : [];

  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <BackButton />
        <Typography variant="h5">{standard.name}</Typography>
      </Stack>

      <Details standard={standard} />
      <Guides guides={guides} />
    </Stack>
  );
}
type Standard = NonNullable<GetStandardQuery['standard']>;

const Details: FC<{ standard: Standard }> = ({ standard }) => {
  const [deleteStandard] = useMutation(DELETE, { variables: { input: { id: standard?.id } } });
  const navigate = useNavigate();

  let removeStandard = async () => {
    await deleteStandard();
    navigate('/standard');
  };

  const externalUrlBase = `https://online.standard.no/`;

  if (!standard) return null;
  return (
    <StyledPaper>
      <ConnectionStatus standard={standard} externalUrlBase={externalUrlBase} />
      <List dense>
        <StyledListItem>
          <ListLabel variant="subtitle1">Tittel:</ListLabel>
          <ListItemText primary={<Title standard={standard} />} />
        </StyledListItem>
        <StyledListItem>
          <ListLabel variant="subtitle1">Gyldig:</ListLabel>
          <ListItemText primary={standard.isValid ? 'Ja' : 'Nei'} />
        </StyledListItem>
        <StyledListItem>
          <ListLabel variant="subtitle1">Versjon:</ListLabel>
          <ListItemText primary={standard.version} />
        </StyledListItem>
        <StyledListItem>
          <ListLabel variant="subtitle1">Språk:</ListLabel>
          <ListItemText primary={standard.language} />
        </StyledListItem>
        <StyledListItem>
          <ListLabel variant="subtitle1">Link:</ListLabel>
          <ListItemText primary={standard.link} />
        </StyledListItem>
      </List>
      <Stack direction="row" spacing={2}>
        {standard.guides && standard.guides.length === 0 && (
          <Button variant="contained" color="error" onClick={removeStandard} aria-label="Remove standard">
            Fjern standard
          </Button>
        )}
        {standard.externalId && !standard.unableToSync && (
          <Button href={externalUrlBase + standard.externalId} target="blank">
            Se på standard.no
          </Button>
        )}
      </Stack>
      <Box justifyItems="end">
        <Typography component="div" variant="caption" color="textSecondary">
          Sist endret: {formatDateOnly(standard.lastChangedAt)}
        </Typography>
      </Box>
    </StyledPaper>
  );
};

const Title: FC<{ standard: Standard }> = ({ standard }) => {
  const [standardUpdateTitle] = useMutation(UPDATE_TITLE);

  if (!standard) return null;
  const setValue = async (input: string) => {
    await standardUpdateTitle({ variables: { input: { id: standard.id, title: input } } });
  };
  return <TextEditor value={standard.title ?? ''} setValue={setValue} />;
};

const TextEditor: FC<{
  value: string;
  setValue: (v: string) => Promise<void>;
}> = ({ value, setValue }) => {
  return <EditableTextField value={value} onUpdate={setValue} />;
};

const StyledListItem = styled(ListItem)`
  padding: 0;
  margin: 0;
`;

type GuideModel = NonNullable<Standard['guides']>[0];
const Guides: FC<{ guides: GuideModel[] }> = ({ guides }) => {
  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        Anvisninger
      </Typography>
      <Table size="small">
        <ColWidths widths={[40, 40, null, 150, 40]} />
        <TableHead>
          <TableRow>
            <TableCellHead>Status</TableCellHead>
            <TableCellHead>Nummer</TableCellHead>
            <TableCellHead>Tittel</TableCellHead>
            <TableCellHead>Fagområde</TableCellHead>
            <TableCellHead>Sist publisert</TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {guides.map((x) => {
            if (!x) return null;
            const categoryTitle = x.mainCategory ? x.mainCategory.title : undefined;
            return (
              <TableRow key={x.id}>
                <TableCellIcon>
                  <GuideStatusIcon
                    status={x.status ?? GuideStatus.Planned}
                    hasRevision={x.ongoingRevision !== null}
                    hasChangesSinceLastPublish={x.hasChangesSinceLastPublish}
                  />
                </TableCellIcon>
                <TableCell>
                  <StyledLink to={`/guide/${x.id}`}>{x.docName}</StyledLink>
                </TableCell>
                <TableCellEllipsis title={x.docTitle != null ? x.docTitle : undefined}>{x.docTitle}</TableCellEllipsis>
                <TableCellEllipsis title={categoryTitle != null ? categoryTitle : undefined}>{categoryTitle ?? null}</TableCellEllipsis>
                <TableCell>{formatDateOnly(x.lastPublishedAt)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledPaper>
  );
};

const ConnectionStatus: FC<{ standard: Standard; externalUrlBase: string }> = ({ standard, externalUrlBase }) => {
  const modal = useModal<UpdateExternalIdDialogProps, undefined>({ data: { urlBase: externalUrlBase, standard } });
  const url = externalUrlBase + standard.externalId;
  if (standard.externalId && !standard.unableToSync) return null;
  const hasExternalId = !!standard.externalId;
  console.log('standard.externalId', standard);
  return (
    <Alert
      severity={hasExternalId ? 'error' : 'info'}
      action={
        <Button startIcon={<Edit />} onClick={() => modal.open()}>
          Endre kobling
        </Button>
      }>
      {hasExternalId ? <ConnectionErrorMessage url={url} /> : 'Mangler kobling til standard.no'}
      {modal.isOpen && <UpdateExternalIdDialog modal={modal} />}
    </Alert>
  );
};

const ConnectionErrorMessage: FC<{ url: string }> = ({ url }) => {
  return (
    <span>
      Klarer ikke å oppdatere denne fra{' '}
      <StyledAnchor href={url} target="blank">
        {url}
      </StyledAnchor>
    </span>
  );
};

type UpdateExternalIdDialogProps = { urlBase: string; standard: Standard };
const UpdateExternalIdDialog: FC<{ modal: Modal<UpdateExternalIdDialogProps, undefined> }> = ({ modal }) => {
  const standard = modal.data.standard;
  const [value, setValue] = useState(standard.externalId);
  const [errorText, setErrorText] = useState<string>();
  const [updateExternalId, { loading }] = useMutation(UPDATE_EXTERNAL_ID, { variables: { input: { id: standard.id, externalId: value } } });

  const handleClose = async () => {
    modal.close();
  };

  const handleOk = async () => {
    const result = await updateExternalId();
    if (result.errors || result.data?.standardUpdateExternalId.errors) {
      if (result.data?.standardUpdateExternalId.errors?.some((x) => x.code === 'StandardNotFoundError')) setErrorText('Finner ikke standarden');
      else if (result.data?.standardUpdateExternalId.errors?.some((x) => x.code === 'StandardArgumentError'))
        setErrorText('Klarer ikke lese inn standarden.');
      else setErrorText('Ukjent feil');
    } else modal.close();
  };

  const changeValue = (v: string) => {
    setValue(v);
    setErrorText(undefined);
  };

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Endre kobling til standard.no</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          error={errorText !== undefined}
          helperText={errorText}
          value={value ?? ''}
          onChange={(e) => changeValue(e.target.value ?? undefined)}
          label="Kobling"
          slotProps={{ input: { startAdornment: <InputAdornment position="start">{modal.data.urlBase}</InputAdornment> } }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Avbryt</Button>
        <Button
          onClick={handleOk}
          variant="contained"
          color="primary"
          disabled={!value || value === standard.externalId || errorText !== undefined}
          startIcon={loading ? <CircularProgress size={25} /> : null}>
          Endre
        </Button>
      </DialogActions>
    </Dialog>
  );
};
