import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { ADD } from './committeeMeetings.graphql';
import { Modal } from '../dialogs/useModal';
import { CommitteeMeetingAddPayload } from '../__generated__/graphql';
import moment, { Moment } from 'moment';
import { DatePicker } from '@mui/x-date-pickers';

export type CommitteeMeetingModelAdded = NonNullable<CommitteeMeetingAddPayload['committeeMeetingModel']>;

export function AddMeetingDialog({ modal }: { modal: Modal<undefined, CommitteeMeetingModelAdded> }) {
  const defaultDate = moment().add(1, 'days');
  const [workingDate, setWorkingDate] = useState<Moment | null>(defaultDate);

  const [addMeeting, { loading }] = useMutation(ADD, {
    variables: {
      input: {
        date: workingDate!.toISOString(),
      },
    },
  });

  const handleClose = async () => {
    modal.close();
  };

  const handleOk = async () => {
    const result = await addMeeting();
    const newMeeting = result.data?.committeeMeetingAdd.committeeMeetingModel;
    if (newMeeting) modal.close(newMeeting);
  };

  return (
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Legg til nytt møte</DialogTitle>
        <DialogContent dividers>
          <DatePicker
            format="DD.MM.YYYY"
            label="Dato for møte"
            minDate={defaultDate}
            value={workingDate}
            disablePast={true}
            onChange={async (date, context) => {
              if (!context.validationError) {
                setWorkingDate(date);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button
            onClick={handleOk}
            variant="contained"
            color="primary"
            disabled={workingDate === null || loading}
            startIcon={loading ? <CircularProgress size={25} /> : undefined}>
            Legg til
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
