import React from 'react';
import {
  Home,
  Gavel,
  ShowChart,
  LocalLibrary,
  LibraryBooks,
  Loop,
  GroupWork,
  NotificationsActive,
  Reorder,
  People,
  Publish,
  MenuBook,
  SupervisedUserCircle,
  AccountTree,
  Book,
  Dataset,
  Groups,
} from '@mui/icons-material';

import { Shell } from './Shell';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Notice } from './notices/NoticeIcon';
import { Overview as GuideOverview } from './guides/Overview';
import { Details as GuideDetails } from './guides/Details';
import { EditGuideParagraphs } from './guides/EditGuideParagraphs';
import { EditGuideStandards } from './standards/EditGuide';
import { EditGuideCategories } from './categories/EditGuideCategories';
import { GuideLinksEdit } from './guides/GuideLinks';
import { ReleaseOverview } from './guides/ReleaseOverview';

import { ParagraphOverview } from './paragraphs/Overview';
import { Details as ParagraphDetails } from './paragraphs/Details';

import { StandardOverview } from './standards/Overview';
import { StandardDetails } from './standards/Details';

import { Overview as CategoryOverview } from './categories/Overview';
import { Details as CategoryDetails } from './categories/Details';

import { Overview as TasksOverview } from './tasks/Overview';
import { UnreadTasksBadge } from './tasks/UnreadTasksBadge';
import { Overview as NoticeOverview } from './notices/Overview';

import { Overview as RevisionOverview } from './revisions/Overview';
import { Details as RevisionDetails } from './revisions/Details';

import { Overview as ResourceOverview } from './resources/Overview';

import { Dashboard } from './home/Dashboard';
import { Overview as DashboardOverview } from './dashboard/Overview';

import { Overview as Backlog } from './backlog/Overview';
import { Overview as SubjectResources } from './subjectResources/Overview';
import { Details as SubjectResourceDetails } from './subjectResources/Details';
import { Admin as OrganizationAdmin } from './organization/OrganizationAdmin';
import { Overview as Partners } from './partners/Overview';
import { Details as PartnerDetails } from './partners/Details';

import { Overview as Classification } from './classifications/Overview';

import { Overview as BookReferenceOverview } from './bookAdmin/Overview';

import { BookDetail } from './bookAdmin/BookDetail';
import LoadingSpinner from './LoadingSpinner';
import { ErrorBoundary } from 'react-error-boundary';
import { GuideCmsEditPage, GuideCmsReadOnlyPage } from './guides/cms/GuideCmsPage';
import { HearingsPage } from './hearings/HearingsPage';
import { Overview as DataSourcesOverview } from './dataSources/Overview';
import { Details as DataSourceDetails } from './dataSources/Details';
import { useAuth } from 'oidc-react';
import { FeatureFlags, useFeatureFlags } from './unleash';
import { AiSearchDialogPage } from './ai/AiSearchDialogPage';
import { Overview as CommitteeMeetings } from './committeMeetings/Overview';
import { Details as CommitteeMeetingDetails } from './committeMeetings/Details';

export interface RouteDef {
  path: string;
  component: React.ComponentType<any>;
  title?: string;
  icon?: React.ComponentType<any>;
  badge?: React.ComponentType<any>;
}

const routes: RouteDef[] = [
  { path: '/', component: Dashboard, title: 'Hjem', icon: Home },
  { path: '/statistics', component: DashboardOverview, title: 'Statistikk', icon: ShowChart },
  { path: '/task', component: TasksOverview, title: `Oppgaver`, icon: NotificationsActive, badge: UnreadTasksBadge },
  { path: '/task/:id', component: TasksOverview },
  { path: '/notice', component: NoticeOverview, title: `Merknader`, icon: Notice },
  { path: '/notice/:id', component: NoticeOverview },
  { path: '/paragraph', component: ParagraphOverview, title: 'TEK', icon: Gavel },
  { path: '/paragraph/:id', component: ParagraphDetails },
  { path: '/standard', component: StandardOverview, title: 'Standarder', icon: LocalLibrary },
  { path: '/standard/:id', component: StandardDetails },
  { path: '/category', component: CategoryOverview, title: 'Fagområder', icon: GroupWork },
  { path: '/category/:id', component: CategoryDetails },
  { path: '/guide', component: GuideOverview, title: 'Anvisninger', icon: LibraryBooks },
  { path: '/guide/:id', component: GuideDetails },
  { path: '/guide-editor/:id', component: GuideCmsReadOnlyPage },
  { path: '/guide-editor/:id/edit', component: GuideCmsEditPage },
  { path: '/guide/paragraphs/:id', component: EditGuideParagraphs },
  { path: '/guide/standards/:id', component: EditGuideStandards },
  { path: '/guide/categories/:id', component: EditGuideCategories },
  { path: '/guide/hearings/:id', component: HearingsPage },
  { path: '/guide/links/:id', component: GuideLinksEdit },
  { path: '/backlog', component: Backlog, title: 'Planlegging', icon: Reorder },
  { path: '/revision/:id', component: RevisionDetails },
  { path: '/revision', component: RevisionOverview, title: 'Revisjoner', icon: Loop },
  { path: '/resource', component: ResourceOverview, title: 'Ressurser', icon: People },
  { path: '/releases', component: ReleaseOverview, title: 'Publiseringer', icon: Publish },
  { path: '/subjectResource', component: SubjectResources, title: 'Fagressurser', icon: MenuBook },
  { path: '/subjectResource/:id', component: SubjectResourceDetails },
  { path: '/organization', component: OrganizationAdmin },
  { path: '/partner', component: Partners, title: 'Partnere', icon: SupervisedUserCircle },
  { path: '/partner/:id', component: PartnerDetails },
  { path: '/classification', component: Classification, title: 'Klassifikasjonssystem', icon: AccountTree },
  { path: '/bookadmin', component: BookReferenceOverview, title: 'Bøker', icon: Book },
  { path: '/book/:bookTitle', component: BookDetail },
  { path: '/datasource', component: DataSourcesOverview, title: 'Datakilder', icon: Dataset },
  { path: '/datasource/:correlationId', component: DataSourceDetails },
  { path: '/ai-search-dialog', component: AiSearchDialogPage },
  { path: '/meeting', component: CommitteeMeetings, title: 'Utvalgsmøter', icon: Groups },
  { path: '/meeting/:id', component: CommitteeMeetingDetails },
];

const getRoutes = (featureFlags: FeatureFlags) => {
  const routesToUse = routes.filter((route) => {
    if (route.path.indexOf('/datasource') === 0) {
      return featureFlags.dataSources;
    }
    if (route.path.indexOf('/meeting') === 0) {
      return featureFlags.committeeMeetings;
    }
    return true;
  });
  return routesToUse;
};

export function App() {
  const featureFlags = useFeatureFlags();
  const auth = useAuth();
  if (auth.isLoading || !featureFlags.flagsReady) return <LoadingSpinner />;

  const routes = getRoutes(featureFlags);
  const router = createBrowserRouter([
    {
      element: <Shell routes={routes} />,
      children: routes.map((x) => {
        return { path: x.path, element: <x.component />, errorElement: <ErrorBoundary fallback={<div>Something went wrong</div>} /> };
      }),
    },
  ]);
  return (
    <>
      <RouterProvider router={router} fallbackElement={<LoadingSpinner />} />
    </>
  );
}
