import { gql } from '../__generated__';

gql(/* GraphQL */ `
  fragment CommitteeMeetingTopicItem on CommitteeMeetingTopicModel {
    confirmed
    revision {
      id
      authors {
        id
        name
      }
      professionQualityAssurers {
        id
        name
      }
      roles {
        user {
          name
        }
        role
      }
      guide {
        id
        docName
        docTitle
        mainCategory {
          id
          title
        }
      }
    }
  }
`);

export const GET_MEETINGS = gql(/* GraphQL */ `
  query GetCommitteeMeetings($from: DateTime, $cursor: String) {
    committeeMeetings(where: { or: [{ date: { gt: $from } }] }, order: [{ date: ASC }], first: 25, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
      }
      nodes {
        id
        date
        invitationDate
        confirmationDate
        topics {
          revision {
            id
            guide {
              id
              docName
              docTitle
            }
          }
          confirmed
        }
      }
    }
  }
`);

export const GET_MEETING = gql(/* GraphQL */ `
  query GetCommitteeMeeting($id: UUID!) {
    committeeMeeting(id: $id) {
      id
      date
      invitationDate
      confirmationDate
      topics {
        ...CommitteeMeetingTopicItem
      }
    }
  }
`);

export const ADD = gql(/* GraphQL */ `
  mutation AddCommitteeMeeting($input: CommitteeMeetingAddInput!) {
    committeeMeetingAdd(input: $input) {
      committeeMeetingModel {
        id
        date
        invitationDate
        confirmationDate
      }
    }
  }
`);

export const UPDATE_DATE = gql(/* GraphQL */ `
  mutation CommitteeMeetingChangeDate($input: CommitteeMeetingUpdateDateInput!) {
    committeeMeetingUpdateDate(input: $input) {
      committeeMeetingModel {
        id
        date
      }
    }
  }
`);

export const UPDATE_INVITATION_DATE = gql(/* GraphQL */ `
  mutation CommitteeMeetingChangeInvitationDate($input: CommitteeMeetingUpdateInvitationDateInput!) {
    committeeMeetingUpdateInvitationDate(input: $input) {
      committeeMeetingModel {
        id
        invitationDate
      }
    }
  }
`);

export const UPDATE_CONFIRMATION_DATE = gql(/* GraphQL */ `
  mutation CommitteeMeetingChangeConfirmationDate($input: CommitteeMeetingUpdateConfirmationDateInput!) {
    committeeMeetingUpdateConfirmationDate(input: $input) {
      committeeMeetingModel {
        id
        confirmationDate
      }
    }
  }
`);

export const DELETE = gql(/* GraphQL */ `
  mutation CommitteeMeetingRemove($input: CommitteeMeetingRemoveInput!) {
    committeeMeetingRemove(input: $input) {
      committeeMeetingModel {
        id
      }
    }
  }
`);

export const GET_REVISIONS = gql(/* GraphQL */ `
  query GetActiveRevisions {
    revisions(where: { and: [{ canceledAt: { eq: null }, completedAt: { eq: null } }] }, order: [{ title: ASC }]) {
      id
      guide {
        id
        docName
        docTitle
      }
    }
  }
`);

export const ADD_TOPIC = gql(/* GraphQL */ `
  mutation CommitteeMeetingAddTopic($input: CommitteeMeetingAddTopicInput!) {
    committeeMeetingAddTopic(input: $input) {
      committeeMeetingModel {
        id
        topics {
          ...CommitteeMeetingTopicItem
        }
      }
    }
  }
`);

export const UPDATE_TOPIC_STATUS = gql(/* GraphQL */ `
  mutation CommitteeMeetingUpdateTopicStatus($input: CommitteeMeetingUpdateTopicStatusInput!) {
    committeeMeetingUpdateTopicStatus(input: $input) {
      committeeMeetingModel {
        id
        topics {
          ...CommitteeMeetingTopicItem
        }
      }
    }
  }
`);

export const REMOVE_TOPIC = gql(/* GraphQL */ `
  mutation CommitteeMeetingRemoveTopic($input: CommitteeMeetingRemoveTopicInput!) {
    committeeMeetingRemoveTopic(input: $input) {
      committeeMeetingModel {
        id
        topics {
          ...CommitteeMeetingTopicItem
        }
      }
    }
  }
`);

export const MOVE_TOPIC = gql(/* GraphQL */ `
  mutation CommitteeMeetingMoveTopic($input: CommitteeMeetingMoveTopicInput!) {
    committeeMeetingMoveTopic(input: $input) {
      committeeMeetingModel {
        id
        topics {
          ...CommitteeMeetingTopicItem
        }
      }
    }
  }
`);
