import { Add, Edit } from '@mui/icons-material';
import { Box, Checkbox, Divider, FormControlLabel, IconButton, List, ListItem, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { StyledPaper } from '../theme';
import { formatDateOnly } from '../Formatters';
import { GET_MEETINGS } from './committeeMeetings.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GetCommitteeMeetingsQuery } from '../__generated__/graphql';
import { AddMeetingDialog, CommitteeMeetingModelAdded } from './AddMeetingDialog';
import { useModal } from '../dialogs/useModal';
import { useNavigate } from 'react-router-dom';
import { StyledLink } from '../Components';

type CommitteeMeetingsNodes = NonNullable<GetCommitteeMeetingsQuery['committeeMeetings']>['nodes'];
type CommitteeMeeting = NonNullable<CommitteeMeetingsNodes>[number];

export const Overview: FC = () => {
  const addModal = useModal<undefined, CommitteeMeetingModelAdded>({ data: undefined });
  const [showHistoric, setShowHistoric] = useState(false);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const from = showHistoric ? new Date('2000-01-01') : currentDate;
  const { data, loading, fetchMore } = useQuery(GET_MEETINGS, { variables: { from }, fetchPolicy: 'cache-and-network' });
  const navigate = useNavigate();

  const addMeeting = async () => {
    const result = await addModal.open();
    if (result) navigate(`${result.id}`);
  };

  return (
    <StyledPaper>
      <Stack>
        <Typography variant="h5">
          Utvalgsmøter
          <IconButton sx={{ marginLeft: 2, marginBottom: '4px' }} title="Nytt møte" onClick={addMeeting}>
            <Add />
          </IconButton>
        </Typography>
        <Box display="grid">
          <FormControlLabel
            style={{ justifySelf: 'end' }}
            control={<Checkbox onChange={(e) => setShowHistoric(e.target.checked)} />}
            label="Vis historiske"
          />
        </Box>
        {loading && <LoadingSpinner />}
        {data?.committeeMeetings?.totalCount === 0 && (
          <Typography color="secondary" variant="subtitle1">
            Ingen møter registrert
          </Typography>
        )}
        {data?.committeeMeetings?.nodes && (
          <Meetings
            meetings={data.committeeMeetings?.nodes}
            hasMoreMeetings={data.committeeMeetings.totalCount > data.committeeMeetings.nodes.length}
            loading={loading}
            fetchMoreMeetings={async () => {
              await fetchMore({
                variables: { from, cursor: data.committeeMeetings?.pageInfo.endCursor },
                updateQuery(previousData, { fetchMoreResult }) {
                  const updatedMeetings = previousData.committeeMeetings?.nodes?.slice(0) ?? [];
                  const merged = updatedMeetings.concat(fetchMoreResult.committeeMeetings?.nodes ?? []);
                  return { committeeMeetings: { ...fetchMoreResult.committeeMeetings!, nodes: merged } };
                },
              });
            }}
          />
        )}
      </Stack>
      {addModal.isOpen && <AddMeetingDialog modal={addModal} />}
    </StyledPaper>
  );
};

const Meetings: FC<{ meetings: CommitteeMeeting[]; hasMoreMeetings: boolean; loading: boolean; fetchMoreMeetings: () => Promise<void> }> = ({
  meetings,
  hasMoreMeetings,
  fetchMoreMeetings,
  loading,
}) => {
  return (
    <Stack spacing={3} divider={<Divider orientation="horizontal" />}>
      <InfiniteScroller fetchData={fetchMoreMeetings} hasMore={hasMoreMeetings} loading={loading}>
        {meetings
          .filter((x) => x !== null)
          .map((meeting) => (
            <Meeting key={meeting.id} meeting={meeting} />
          ))}
      </InfiniteScroller>
    </Stack>
  );
};

const Meeting: FC<{ meeting: NonNullable<CommitteeMeeting> }> = ({ meeting }) => {
  const navigate = useNavigate();
  const workingSet = meeting.topics ? [...meeting.topics] : [];
  workingSet.sort((a, b) => a?.revision?.guide?.docTitle?.localeCompare(b?.revision?.guide?.docTitle ?? '') ?? 0);
  const confirmed = workingSet.filter((topic) => topic?.confirmed === true);
  const unconfirmed = workingSet.filter((topic) => topic?.confirmed !== true);
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography fontWeight="bold">Utvalgsmøte {formatDateOnly(meeting.date, 'DD.MMMM')}</Typography>
        <div>
          <IconButton title="Rediger møte" onClick={() => navigate(`${meeting.id}`)} size="small">
            <Edit />
          </IconButton>
        </div>
      </Stack>
      <div>
        <Typography>Utsendelse: {formatDateOnly(meeting.invitationDate)}</Typography>
        <Typography>Bekreftelse: {formatDateOnly(meeting.confirmationDate)}</Typography>
      </div>
      {workingSet.length === 0 && (
        <Typography color="secondary" variant="subtitle2">
          Ingen anvisninger registert som aktuelle for møtet
        </Typography>
      )}
      <GuideList guides={confirmed} label="Bekreftet" />
      <GuideList guides={unconfirmed} label="Aktuelle" />
    </Stack>
  );
};

type CommitteeTopic = NonNullable<NonNullable<CommitteeMeeting>['topics']>[number];
const GuideList: FC<{ guides: CommitteeTopic[]; label: string }> = ({ guides, label }) => {
  if (guides.length === 0) return null;

  guides.sort((a, b) => a?.revision?.guide?.docName?.localeCompare(b?.revision?.guide?.docName ?? '') ?? 0);

  return (
    <Stack>
      <Typography>{label}</Typography>
      <List dense sx={{ paddingTop: 0.25, paddingBottom: 0.25 }}>
        {guides
          .filter((x) => x != null)
          .map((topic) => (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={topic.revision?.guide?.id}>
              <Stack direction="row" spacing={2}>
                <StyledLink to={`/guide/${topic.revision?.guide?.id}`}>{topic.revision?.guide?.docName}</StyledLink>
                <Typography key={topic.revision?.guide?.id}>{topic.revision?.guide?.docTitle}</Typography>
              </Stack>
            </ListItem>
          ))}
      </List>
    </Stack>
  );
};

const InfiniteScroller: FC<{ children: React.ReactNode; fetchData: () => Promise<void>; hasMore: boolean; loading: boolean }> = ({
  children,
  fetchData,
  hasMore,
  loading,
}) => {
  useEffect(() => {
    const handleScroll = async () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      if (hasMore && !loading && scrollTop + clientHeight >= scrollHeight - 20) {
        console.log('Fetch more list items!');
        await fetchData();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [fetchData]);

  return (
    <>
      {children}
      {loading && <LoadingSpinner size={25} />}
    </>
  );
};
